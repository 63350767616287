import {cva} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';

export const SectionStyles = cva('bg-[#F6F6F1]', {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'bg-transparent',
      [BLOG_PAGE_TYPE.ENGINEERING]: [
        'bg-[#E7ECFB]',
        '[&_h5]:text-engineering-light-text [&_h5]:text-t2',
        '[&_.header-link]:text-engineering-light-text',
      ],
    },
  },
});
