import cn from 'classnames';

import {
  BLOG_PAGE_TYPE,
  type Article,
  type GroupedArticlesListProps,
} from '@/components/blog/types';
import {useTranslations} from '@/hooks/useTranslations';
import BlogSection from '@/components/pages/blog/BlogSection/BlogSection';
import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import {SectionStyles} from '@/components/pages/blog/LatestSection/styles';

export default function LatestSection({
  primaryArticles,
  enBlogPath,
  sectionHead,
  blogType,
}: GroupedArticlesListProps) {
  const {localizePath} = useTranslations();
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;

  const blogCardMode = () => {
    switch (blogType) {
      case BLOG_PAGE_TYPE.ENTERPRISE:
        return 'dark';
      case BLOG_PAGE_TYPE.ENGINEERING:
        return 'light';
      default:
        return;
    }
  };

  return (
    <BlogSection
      heading={sectionHead?.heading}
      className={SectionStyles({blogType})}
      link={{
        text: sectionHead?.link?.text,
        url: localizePath(enBlogPath + 'latest'),
      }}
      headingTag="h5"
    >
      <div
        className={cn(
          {
            '!grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-4 desktop:gap-6 tablet:gap-y-6 desktop:gap-y-9':
              isEnterpriseBlog,
          },
          'flex justify-between gap-4 tablet:gap-6 tablet-xl:gap-4 flex-wrap flex-col tablet:flex-row',
        )}
      >
        {primaryArticles.map((article: Article) => (
          <div
            key={article.title}
            className={cn(
              {
                'border-button-dark-secondary-border border-opacity-20':
                  isEnterpriseBlog,
              },
              'border-t border-shade-30 basis-full tablet:basis-[48%] tablet-xl:basis-[23%]',
            )}
          >
            <BlogCard
              blogType={blogType}
              article={article}
              enBlogPath={enBlogPath}
              type="noImage"
              mode={blogCardMode()}
              preferredTag={isEnterpriseBlog ? article.topicHandle : undefined}
            />
          </div>
        ))}
      </div>
    </BlogSection>
  );
}
